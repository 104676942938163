@font-face {
  font-family: 'LVMH';
  src: local('LVMH'), url(./assets/fonts/lvmh-regular.otf) format('otf');
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.css-ofrcno-MuiGrid-root>.MuiGrid-item {
  padding-left: 16px;
  text-align: center;
}


header {
  background-color: #151F6D !important;
  color: #fff;
  position: sticky !important;
  top: 0;
  width: 100%;
}


#page-container {
  position: relative;
  min-height: 100vh;
}

#content-wrap {
  padding-bottom: 4.5rem;    /* Footer height */
}

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem;            /* Footer height */
}

#table1{
  overflow-wrap: break-word;
}