body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'LVMH';
  src: local('LVMH'), url(../../static/media/lvmh-regular.a00eb568.otf) format('otf');
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


.css-ofrcno-MuiGrid-root>.MuiGrid-item {
  padding-left: 16px;
  text-align: center;
}


header {
  background-color: #151F6D !important;
  color: #fff;
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0;
  width: 100%;
}


#page-container {
  position: relative;
  min-height: 100vh;
}

#content-wrap {
  padding-bottom: 4.5rem;    /* Footer height */
}

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem;            /* Footer height */
}

#table1{
  overflow-wrap: break-word;
}
@media(max-width: 413px) {
    #address{
        font-size: 10px !important;
        margin-bottom: 0px;
        margin-right: 10px !important;
    }
    #logout{
        font-size: 10px !important;
    }
}


@media(max-width: 339px) {
    #address{
        margin-bottom: 10px !important;
        margin-right: 0px !important;
    }
}



.css-2uchni {
    text-align: end;
}
