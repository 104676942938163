@media(max-width: 413px) {
    #address{
        font-size: 10px !important;
        margin-bottom: 0px;
        margin-right: 10px !important;
    }
    #logout{
        font-size: 10px !important;
    }
}


@media(max-width: 339px) {
    #address{
        margin-bottom: 10px !important;
        margin-right: 0px !important;
    }
}



.css-2uchni {
    text-align: end;
}